import { createContext, useState } from "react";
import TokenService from "../services/TokenService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(TokenService.getLocalAccessToken());
  const userIsLoggedIn = !!token || false;

  const contextValue = {
    token: token,
    setToken: setToken,
    isLoggedIn: userIsLoggedIn,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
