import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Error403 = () => {
  useEffect(() => {
    document.title = "Papipets | Non autorisé";
  }, []);
  return (
    <>
      <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <div className="d-flex justify-content-center error-title lh-1">
                <span className="d-flex justify-content-center align-items-center align-self-center text-primary">
                  4
                </span>
                <span className="d-flex justify-content-center align-items-center align-self-center text-primary px-5">
                  0
                </span>
                <span className="d-flex justify-content-center align-items-center align-self-center text-primary">
                  3
                </span>
              </div>
              <h2 className="mb-0 mt-2">Accès refusé.</h2>
              <p className="mt-2">
                Vous n'avez pas la permission d'accéder à cette page.
              </p>
              <div className="d-flex justify-content-center">
                <Link to="/" className="btn btn-light">
                  Retour à l'accueil
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Error403;
