import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Error403 from "../views/errors/Error403";

const RequireRole = (props) => {
  const user = useSelector((state) => state.user.value);

  const matchRoles = () => {
    if (user != null && user.roles?.length > 0) {
      const roles = props.roles.split(",");
      let exist = false;
      roles.some((role) => {
        if (user.roles.includes(role)) {
          exist = true;
          return true;
        }
        return false;
      });
      return exist;
    }
    return false;
  };

  return matchRoles() ? <Outlet /> : <Error403 />;
};

export default RequireRole;
