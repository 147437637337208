import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

const RequireAuth = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/sign-in" replace={true} />
  );
};

export default RequireAuth;
