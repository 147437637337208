import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const recipeSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    add: (state, action) => {
      state.value = [...state.value, action.payload];
    },
    remove: (state, action) => {
      state.value = state.value.filter((item) => item.id != action.payload);
    },
    update: (state, action) => {
      state.value = [...state.value].map((item) => {
        if (item.id == action.payload.id) return action.payload;
        else return item;
      });
    },
  },
});

export const { set, add, remove, update } = recipeSlice.actions;

export default recipeSlice.reducer;
