import React, { useContext, Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import AuthContext from "./context/AuthProvider";
import RequireAuth from "./hooks/RequireAuth";
import RequireRole from "./hooks/RequireRole";

const Default = lazy(() => import("./layouts/Default"));
const Simple = lazy(() => import("./layouts/Simple"));
const OrderLayout = lazy(() => import("./layouts/OrderLayout"));
const SignIn = lazy(() => import("./views/auth/SignIn"));
const SignUp = lazy(() => import("./views/auth/SignUp"));
const ConfirmMail = lazy(() => import("./views/auth/ConfirmMail"));
const RecoverPassword = lazy(() => import("./views/auth/RecoverPassword"));
const ResetPassword = lazy(() => import("./views/auth/ResetPassword"));
const Dashboard = lazy(() => import("./views/dashboard/Dashboard"));
const Orders = lazy(() => import("./views/orders/Orders"));
const SingleOrder = lazy(() => import("./views/orders/SingleOrder"));
const NewOrder = lazy(() => import("./views/orders/NewOrder"));
const Checkout = lazy(() => import("./views/orders/Checkout"));
const Settings = lazy(() => import("./views/settings/Settings"));
const PackLayout = lazy(() => import("./views/packs/PackLayout"));
const Packs = lazy(() => import("./views/packs/Packs"));
const NewPack = lazy(() => import("./views/packs/NewPack"));
const EditPack = lazy(() => import("./views/packs/EditPack"));
const RecipeLayout = lazy(() => import("./views/recipes/RecipeLayout"));
const Recipes = lazy(() => import("./views/recipes/Recipes"));
const NewRecipe = lazy(() => import("./views/recipes/NewRecipe"));
const EditRecipe = lazy(() => import("./views/recipes/EditRecipe"));
const DogLayout = lazy(() => import("./views/dogs/DogLayout"));
const Dogs = lazy(() => import("./views/dogs/Dogs"));
const NewDog = lazy(() => import("./views/dogs/NewDog"));
const EditDog = lazy(() => import("./views/dogs/EditDog"));
const Newsletter = lazy(() => import("./views/newsletter/Newsletter"));
const DownloadInvoices = lazy(() =>
  import("./views/invoices/DownloadInvoices")
);
const Users = lazy(() => import("./views/users/Users"));
const  Profile = lazy(() => import( "./views/profile/Profile"));
const Error404 = lazy(() => import("./views/errors/Error404"));

const App = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Suspense
      fallback={
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" role="status" />
        </div>
      }
    >
      <Routes>
        <Route element={<RequireAuth />}>
          <Route element={<Default />}>
            <Route path="/" element={<Dashboard />} />
            <Route element={<RequireRole roles="administrateur" />}>
              <Route path="/settings/" element={<Settings />}></Route>
              <Route element={<PackLayout />}>
                <Route path="/packs/" element={<Packs />}></Route>
                <Route path="/packs/new" element={<NewPack />}></Route>
                <Route path="/packs/:id" element={<EditPack />}></Route>
              </Route>
              <Route element={<RecipeLayout />}>
                <Route path="/recipes/" element={<Recipes />}></Route>
                <Route path="/recipes/new" element={<NewRecipe />}></Route>
                <Route path="/recipes/:id" element={<EditRecipe />}></Route>
              </Route>
              <Route path="/newsletter" element={<Newsletter />}></Route>
              <Route path="/orders/:id" element={<SingleOrder />}></Route>
              <Route path="/invoices" element={<DownloadInvoices />}></Route>
              <Route path="/users" element={<Users />}></Route>
            </Route>
            <Route element={<RequireRole roles="client" />}>
              <Route element={<DogLayout />}>
                <Route path="/dogs/" element={<Dogs />}></Route>
                <Route path="/dogs/new" element={<NewDog />}></Route>
                <Route path="/dogs/:id" element={<EditDog />}></Route>
              </Route>
            </Route>
            <Route element={<RequireRole roles="administrateur,client" />}>
              <Route path="/orders" element={<Orders />}></Route>
            </Route>
            <Route path="/profile" element={<Profile />}></Route>
          </Route>
          <Route element={<OrderLayout />}>
            <Route element={<RequireRole roles="client" />}>
              <Route path="/orders/new/:id" element={<NewOrder />}></Route>
              <Route path="/orders/checkout" element={<Checkout />}></Route>
            </Route>
          </Route>
        </Route>
        <Route element={<Simple />}>
          <Route
            path="/auth/sign-in"
            element={
              !isLoggedIn ? <SignIn /> : <Navigate to="/" replace={true} />
            }
          />
          <Route
            exact
            path="/auth/sign-up"
            element={
              !isLoggedIn ? <SignUp /> : <Navigate to="/" replace={true} />
            }
          />
          <Route exact path="/auth/confirm-mail" element={<ConfirmMail />} />
          <Route exact path="/auth/recover-password" element={<RecoverPassword />} />
          <Route exact path="/auth/reset-password" element={<ResetPassword />} />
        </Route>
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default App;
