import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breeds: [],
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setBreeds: (state, action) => {
      state.breeds = action.payload;
    },
  },
});

export const { setBreeds } = registerSlice.actions;

export default registerSlice.reducer;
