import { configureStore } from "@reduxjs/toolkit";
import registerReducer from "./registerSlice";
import userReducer from "./userSlice";
import packsReducer from "./packSlice";
import recipeReducer from "./recipeSlice";
import dogsReducer from "./dogSlice";

const Store = configureStore({
  reducer: {
    register: registerReducer,
    user: userReducer,
    packs: packsReducer,
    recipes: recipeReducer,
    dogs: dogsReducer,
  },
});

export default Store;
